<template>
	<div class="container">
		<div>
			<div class="box"></div>
			<!-- <div class="box"></div>
			<div class="box"></div>
			<div class="box"></div>
			<div class="box"></div>
			<div class="box"></div>
			<div class="box"></div>
			<div class="box"></div> -->
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			
		}
	},
	methods: {
		
	},
	mounted(){
		
	},
}
</script>

<style lang="less" scoped>
.container{
	margin-left: 200px;
	margin-top: 200px;
	width: 800px;
	height: 400px;
	border: 1px solid red;
	overflow: auto;
	> div{
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	.box{
		flex-shrink: 0;
		width: 1000px;
		height: 700px;
		border: 1px solid #000;
	}
}
</style>